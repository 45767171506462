
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, url } from '@vuelidate/validators'
import context from '@cloudfun/core'

export default defineComponent({
  setup() {
    const application = context.root
    const configuration = reactive({
      AdminTitle: '',
      PortalTitle: '',
      PortalMetaKeywords: '',
      PortalMetaDescription: '',
      ServicePhone: '',
      CompanyFax: '',
      CompanyAddress: '',
      ServiceEmail: '',
      BusinessHours: '',
      Facebook: '',
      Instagram: '',
      LINE: '',
      ShippingFee: 0,
      ConvenientStoreShippingFee: 0,
      PointsRate: 0,
      TrackingCode: ''
    })

    const rules = {
      AdminTitle: { required },
      PortalTitle: { required },
      PortalMetaKeywords: { required },
      PortalMetaDescription: { required },
      ServicePhone: {},
      CompanyFax: {},
      CompanyAddress: {},
      ServiceEmail: { email },
      BusinessHours: { required },
      Facebook: { url },
      Instagram: { url },
      LINE: { url },
      ShippingFee: { required },
      ConvenientStoreShippingFee: { required },
      PointsRate: { required },
      TrackingCode: {}
    }

    const validate = useVuelidate(rules, toRefs(configuration))

    const save = () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        context.send('warning', {
          subject: '資料有誤',
          content: '資料驗證失敗，請依提示進行修正後再試一次'
        })
      } else if (application) {
        application.model.dispatch('configuration/update', configuration).then(
          () => { // success
            application.model.dispatch('configuration/read')
            context.send('info', {
              subject: '更新成功',
              content: '系統配置已更新'
            })
          },
          failure => {
            context.send('error', {
              subject: '更新失敗',
              content: failure.message
            })
          }
        )
      }
    }

    onMounted(() => {
      if (application) application.model.dispatch('configuration/read').then(value => Object.assign(configuration, value));
    })

    return {
      configuration,
      validate,
      save
    }
  }
})
